<template lang="html">
  <div class="layout--main">
    <div class="container mt-12 pb-12">

      <div class="row mb-8 mt-16">
          <router-link to="/Calculadora-datos">
            <button type="button" class="btn regresar w-48 flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 30 22" fill="none">
                <path d="M27.5 22V15.1643C27.5 13.75 26.9722 12.5452 25.9167 11.55C24.8611 10.5548 23.5833 10.0571 22.0833 10.0571H4.75L11.1667 16.1071L9.41667 17.7571L0 8.87857L9.41667 0L11.1667 1.65L4.75 7.7H22.0833C24.25 7.7 26.1111 8.42679 27.6667 9.88036C29.2222 11.3339 30 13.0952 30 15.1643V22H27.5Z" fill="#FF7150"/>
              </svg>
              <span class="ml-4">
                Regresar
              </span>
            </button>
          </router-link>
        </div>

      <div class="row flex flex-col mt-4">
        Ordenar por
        <div class="flex flex-row mt-4">
          <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
            <label for="flexRadioDefault1">Mensualidad</label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
            <label for="flexRadioDefault2">Cat</label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
            <label for="flexRadioDefault3">Tasa</label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4">
            <label for="flexRadioDefault4">Pago total</label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option5">
            <label for="flexRadioDefault5">Con promosiones</label>
          </div>
        </div>

        </div>
      </div>

      <div class="row mt-8 p-4 bg-calculadora">
        <div class="row flex flex-row">
          <div class="col-sm-3 font-semibold tabla-dato-prod">Banco</div>
          <div class="col-sm-3 font-semibold tabla-dato-prod">Producto</div>
          <div class="col-sm-3 font-semibold tabla-dato-prod">Mensualidad
            <svg xmlns="http://www.w3.org/2000/svg" height="20"  width="20" viewBox="0 -960 960 960"  fill="#000"><path d="m 477,-246 158,-157 -42,-42 -85,84 v -359 h -60 v 359 l -85,-84 -42,42 z m 3,166 c -54.66667,0 -106.33333,-10.5 -155,-31.5 -48.66667,-21 -91.16667,-49.66667 -127.5,-86 -36.33333,-36.33333 -65,-78.83333 -86,-127.5 -21,-48.66667 -31.5,-100.33333 -31.5,-155 0,-55.33333 10.5,-107.33333 31.5,-156 21,-48.66667 49.66667,-91 86,-127 36.33333,-36 78.83333,-64.5 127.5,-85.5 48.66667,-21 100.33333,-31.5 155,-31.5 55.33333,0 107.33333,10.5 156,31.5 48.66667,21 91,49.5 127,85.5 36,36 64.5,78.33333 85.5,127 21,48.66667 31.5,100.66667 31.5,156 0,54.66667 -10.5,106.33333 -31.5,155 -21,48.66667 -49.5,91.16667 -85.5,127.5 -36,36.33333 -78.33333,65 -127,86 -48.66667,21 -100.66667,31.5 -156,31.5 z m 0,-60 c 94.66667,0 175,-33.16667 241,-99.5 66,-66.33333 99,-146.5 99,-240.5 0,-94.66667 -33,-175 -99,-241 -66,-66 -146.33333,-99 -241,-99 -94,0 -174.16667,33 -240.5,99 -66.33333,66 -99.5,146.33333 -99.5,241 0,94 33.16667,174.16667 99.5,240.5 66.33333,66.33333 146.5,99.5 240.5,99.5 z"/></svg>
          </div>
          <div class="col-sm-3 font-semibold tabla-dato-prod"> Acciones</div>
        </div>
      </div>

      <div class="row pt-4 pb-4">
        <div class="row flex flex-row alinear-items">
          <div class="col-sm-3 text-center">
            <img class="w-32 mr-6" src="@/assets/iconos/scotianbank.png" alt="">
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            CITIBANAMEX Mercado Abierto
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            $ 7,560,790.00
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod flex flex-row justify-between"> 
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="40"  width="40" viewBox="0 -960 960 960"  fill="#006D4E"><path d="M300-286q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm132 328h244v-60H432v60Zm0-164h244v-60H432v60Zm0-164h244v-60H432v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>    
            </div>
            <div><button type="button" class="btn btn-visualizar" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Visualizar</button></div>         
          </div>
        </div>
      </div>
      <div class="row pt-4 pb-4">
        <div class="row flex flex-row alinear-items">
          <div class="col-sm-3 text-center">
            <img class="w-32 mr-6" src="@/assets/iconos/scotianbank.png" alt="">
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            CITIBANAMEX Mercado Abierto
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            $ 7,560,790.00
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod flex flex-row justify-between"> 
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="40"  width="40" viewBox="0 -960 960 960"  fill="#006D4E"><path d="M300-286q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm132 328h244v-60H432v60Zm0-164h244v-60H432v60Zm0-164h244v-60H432v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>
            </div>
            <div><button type="button" class="btn btn-visualizar" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Visualizar</button></div>         
          </div>
        </div>
      </div>
      <div class="row pt-4 pb-4">
        <div class="row flex flex-row alinear-items">
          <div class="col-sm-3 text-center">
            <img class="w-32 mr-6" src="@/assets/iconos/scotianbank.png" alt="">
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            CITIBANAMEX Mercado Abierto
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            $ 7,560,790.00
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod flex flex-row justify-between"> 
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="40"  width="40" viewBox="0 -960 960 960"  fill="#006D4E"><path d="M300-286q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm132 328h244v-60H432v60Zm0-164h244v-60H432v60Zm0-164h244v-60H432v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>    
            </div>
            <div><button type="button" class="btn btn-visualizar" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Visualizar</button></div>         
          </div>
        </div>
      </div>
      <div class="row pt-4 pb-4">
        <div class="row flex flex-row alinear-items">
          <div class="col-sm-3 text-center">
            <img class="w-32 mr-6" src="@/assets/iconos/scotianbank.png" alt="">
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            CITIBANAMEX Mercado Abierto
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod">
            $ 7,560,790.00
          </div>
          <div class="col-sm-3 text-center tabla-dato-prod flex flex-row justify-between"> 
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="40"  width="40" viewBox="0 -960 960 960"  fill="#006D4E"><path d="M300-286q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm132 328h244v-60H432v60Zm0-164h244v-60H432v60Zm0-164h244v-60H432v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>    
            </div>
            <div><button type="button" class="btn btn-visualizar" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Visualizar</button></div>          
          </div>
        </div>
      </div>
    </div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container p-4 bg-modal-producto flex flex-row justify-center marco-contendor">
          <div class="col-sm-10 pt-8 pb-8 pr-32 pl-32 bg-white">
            <div class="row w-full flex flex-row justify-center">
              <img class="w-48" src="@/assets/images/pages/logo-soc.png" alt="">
            </div>
            <div class="row mt-16">
              <span class="font-bold texto-verde-imp">Hola Silvia</span>
            </div>
            <div class="row mt-8 text-justify">
              <p>Tu asesor inmobiliario<span> Nombre del Asesor</span>realizo una simulaci&oacute;n con las siguientes conficiones:</p>
            </div>
            <div class="row mt-8">
              <span class="font-bold texto-verde-imp">&bull;Valor de la vivienda:</span>
              <span class="font-bold texto-verde-imp">&bull;Monto de cr&eacute;dito:</span>
              <span class="font-bold texto-verde-imp">&bull;Enganche:</span>
            </div>
            <div class="row mt-8 text-justify">
              <p>Trabajamos de la mano de un asesor hipotecario <span>Nombre del asesor supervisor</span> que tiene alianza con las principales
              instituciones financieras de M&eacute; esto nos permite ayudarte a encontrar la mejor opci&oacute;n crediticia de acuerdo con tu perfil
              y necesidades.
              </p>
            </div>
            <div class="row mt-8">
              <div class="col-md-12 flex flex-row justify-between aling-bancos ">
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/afirme.png" alt="">                </div>
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/Mifel.png" alt="">
                </div>
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/banorte.png" alt="">
                </div>
                <div class="col-sm-2">
                  <img class="w-full" src="@/assets/iconos/citibanamex.png" alt="">
                </div>
              </div>
            </div>

            <div class="row mt-4 mb-8">
              <div class="col-md-12 flex flex-row justify-between aling-bancos">
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/hsbc.png" alt="">
                </div>
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/santander.png" alt="">
                </div>
                <div class="col-sm-2">
                  <img class="w-full mr-6" src="@/assets/iconos/scotianbank.png" alt="">
                </div>
                <div class="col-sm-2">
                  <img class="w-full" src="@/assets/iconos/bx+.png" alt="">
                </div>
              </div>
            </div>

            <div class="row">
              <p class="texto-verde-imp">Conoce un estimado de las condiciones de tu cr&eacute;dito</p>
            </div>

            <div class="row">
              <span class="font-bold texto-verde-imp mt-1">&bull;Banco seleccionado:<span class="font-bold texto-verde-imp ml-2">$0.00</span></span>
              <span class="font-bold texto-verde-imp mt-1">&bull;Mensualidad promedio:<span class="font-bold texto-verde-imp ml-2">$0.00</span></span>
              <span class="font-bold texto-verde-imp mt-1">&bull;Tasa estimada:<span class="font-bold texto-verde-imp ml-2">$0.00</span></span>
              <span class="font-bold texto-verde-imp mt-1">&bull;Gastos notariales promedio:<span class="font-bold texto-verde-imp ml-2">$0.00</span></span>
              <span class="font-bold texto-verde-imp mt-1">&bull;Ingreso mínimo:<span class="font-bold texto-verde-imp ml-2">$0.00</span></span>
              <span class="font-bold texto-verde-imp mt-1">&bull;CAT promedio:<span class="font-bold texto-verde-imp ml-2">$0.00</span> </span>
            </div>

            <div class="row mt-8 text-justify">
              <p>
                ¿Te gustaría recibir asesoría de un experto? Te acompañamos desde la elección de tu crédito hasta la
                firma de tus escrituras. ¡Nuestro servicio no tiene costo!
              </p>
            </div>

            <div class="row mt-8 text-justify mb-32">
              <p>
                * Importante: Las condiciones presentadas son informativas y referenciales, por lo que, éstas podrían
                cambiar de acuerdo con el análisis de tu perfil y los parámetros del banco.
              </p>
            </div>

          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-regresar-modal" data-bs-dismiss="modal">Regresar</button>
        <button type="button" class="btn btn-success">Guardar y descargar</button>
      </div>
    </div>
  </div>
</div>

  </div>



</template>

<script>
export default {
  name: "Calculadora-datos"
}
</script>

<style lang="scss" scoped>
.bg-calculadora{
  background-color:#F2F2F2;
  border-radius: 10px;
}
.form-check-label{
  margin-top: 0px !important;
}
.tabla-dato-prod{
  color:#006D4E;
  text-align: center;
}
.btn-visualizar {
    color: #006D4E;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}
.regresar{
  background-color: #fff;
  color: #FF7150;
  border: none;
}
.alinear-items{
  align-items: center;
}
.btn-regresar-modal{
  border: solid 1px #FF7150;
  color: #FF7150;
}
.marco-contendor{
  border-radius: 10px;
}
.bg-modal-producto{
  background-color: #B4B4B4;
}
.texto-verde-imp{
  color: #006D4E;
}
.aling-bancos{
  align-items: baseline;
}
</style>